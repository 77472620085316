// *************** Import *************** //
@use "../foundations/import" as f;

// *************** Styles *************** //

.modalContent {
  border: 5px solid f.$skyblue;

  .modalProfile {

    > h3 {

      > p {

        > span {
          color: f.$skyblue;

        }
      }
    }
  }

  // 職歴リスト
  .data_wrap {

    > li {
      border: 2px solid f.$skyblue;
    }
  }

  .nonData {
    border: 2px dashed f.$skyblue;
  }
}