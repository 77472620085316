/*************** Import ***************/
@use "../../foundations/import" as f;

.title {

  .st0 {
    display: none;
    fill: none;
    stroke: #0A2E45;
    stroke-width: 9;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: #0A2E45;
  }
  .st2 {
    fill: f.$skyblue;
  }
}

.logo {
  width: 40px;
  height: 48px;
  margin: auto;

  .st0{
    fill: f.$white;
  }
  .st1{
    fill: f.$skyblue;
  }
}