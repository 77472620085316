/*************** Property ***************/
// 要素の形
$display: (
  block: 'block',
  inline-block: 'inline-block',
  flex: 'f',
  inline-flex: 'inline-flex',
);

// 位置
$position: (
  fixed: 'f',
  sticky: 's',
  absolute: 'a',
  relative: 'r',
);

// 方向
$direction: (
  top: 't',
  right: 'r',
  bottom: 'b',
  left: 'l',
  center: 'c',
);

// 単位
$unit: (
  rem: 'rem',
  per: '%',
  px: 'px',
  vh: 'vh',
  vw: 'vw',
  deg: 'deg'
);

// 軸
$axis: (
  X: 'X',
  Y: 'Y'
);

// Flex
$flexType: (
  flex-direction: 'fd',
  justify-content: 'jc',
  align-items: 'ai',
  align-content: 'ac',
  align-self: 'as'
);

// Flex 方向
$flexDirection: (
  row: 'row',
  row-reverse: 'rowr',
  column: 'col',
  column-reverse: 'colr',
  flex-start: 'fs',
  flex-end: 'fe',
  center: 'center',
  space-between: 'sb',
  space-around: 'sa',
  strech: 'st',
  baseline: 'ba'
);

// transform 種類
$transform: (
  translateX: 'tX',
  translateY: 'tY',
  rotate: 'ro',
  scale: 'sc',
  skew: "sk"
);

// overflow
$overflow: (
  scroll: 'sc',
  hidden: 'hi',
  visible: 'vi',
  no-display: 'nodis',
  no-content: 'nocon',
);

$objectType: (
  fill: 'f',
  contain: 'con',
  cover: 'c',
  none: 'no',
  scale-down: 'sc'
);

$whiteSpace: (
  nowrap: 'nw',
  normal: 'nm',
  pre: 'pre',
  pre-wrap: 'prew',
  pre-line: 'prel',
  break-spaces: 'bs'
);

$wordBreak: (
  break-all: 'ba',
  keep-all: 'ka'
);

$cursor: (
  move: 'm',
  vertical-text: 'vt',
  help: 'h',
  zoom-in: 'zi',
  zoom-out: 'o',
  grab: 'g',
  grabbing: 'gi',
  not-allowed: 'na',
  wait: 'w',
  pointer: 'p'
);

/*************** BreakPoint ***************/
// pc
$breakpoint-pc: 1024px;
// tablet
$breakpoint-tablet: 768px;
// mobile
$breakpoint-mobile: 480px;

/*************** Style ***************/
// padding
$input-padding: 4px;

/*************** Table ***************/
$max-tableSize: 55;
$columnWidth-1: 20px;
// Stickyセルのwidth(共通)
$stickyWidth: 70px;
// Sticky以外のセルのwidth(共通)
$notSticky: 200px;
// Sticky以外のセルのwidth(ステータス)
$statusSticky: 35px;
