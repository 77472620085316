/*************** Import ***************/
@use "../../foundations/import" as f;

// *************** Const *************** //
$colorList: f.$red, f.$green, f.$orange, f.$purple;

// *************** Styles *************** //
.tableWrap {
  //overflow-y: scroll;

  .tableFlow {
    overflow: scroll;

    table {

      thead {
        > tr {


        }
      }
    }
  }

  .tableFixed {

    tr {

      th, td {

        &:first-child {
          width: 5rem;
        }
      }

      th {

      }

      td {

        &:first-child {
          background: f.$navy;

          > p, > a {
            color: f.$white;
          }
        }
      }
    }
  }

  // テーブル全体(共通)
  table {
    border-collapse: collapse;
    border-spacing: 0;

    // テーブルの行
    tr {
      display: flex;

      // テーブルのセル
      th, td {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 65px;
        margin: 0;
        padding: 5px;
        border: 1px solid f.$platinum;
        border-top: none;
        border-left: none;
        background-color: f.$white;

        &.statusSelect {
          border-left: 5px solid f.$navy;
        }
      }
    }

    // テーブルのヘッダー
    thead {
      white-space: nowrap;

      tr {

        // テーブルヘッダーのセル
        th {
          background: f.$navy;
          color: f.$white;

          &.colSize-0 {
            width: 0;
            margin: 0;
            padding: 0;
          }

          &.colSize-1 {
            width: calc(2 * #{f.$stickyWidth});
          }

          &.colSize-2 {
            width: calc(4 * #{f.$stickyWidth});
          }

          &.colSize-3 {
            width: calc(6 * #{f.$stickyWidth});
          }

          > p {
            color: f.$white;
          }
        }
      }
    }

    // テーブルボディ
    tbody {
      max-height: 100px;
      overflow-y: scroll;

      .celFlex {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .thumbnail {
          border-radius: 50%;
        }
      }

      // テーブルボディの行
      tr {
        // テーブルボディのセル
        td {
          text-align: left;
          word-break: break-all;

          > a {
            color: f.$white;
          }

          > input, > select, .datepicker {
            padding: 2px;
            border: 1px solid transparent;
            color: f.$navy;
            transition: .3s;

            &:hover, &:focus {
              border: 1px solid f.$navy;
            }
          }

          > input {
            text-align: center;
          }
        }
      }
    }

    th {
      // @for $i from 2 through $max-tableSize {
      //   &:nth-child(#{$i}) {
      //     $randHue: 90 * ($i - 1) + random(45); // 色相環の角度のランダム値を計算
      //     $randHsla1: hsla($randHue, 100, 50, 1); // ランダムカラー 彩度、明度、透明度を固定
      //     $randHsla2: hsla($randHue + random(90), 100, 50, 1); // ランダムカラー 彩度、明度、透明度を固定
      //     border-image: linear-gradient(to right, $randHsla1 0%, $randHsla2 100%);
      //   }
      // }
    }
  }
}

.sort-icon.reverse {
  svg {
    transform: rotate(180deg);
  }
}

.filter-icon {
  svg {
    font-size: 8px;
    margin: auto;
  }
}

.edit-button {
  width: f.$columnWidth-1;
  border: none !important;
  background-color: f.$white !important;
  cursor: pointer;
  pointer-events: none;
  transition: all ease 300ms;

  svg {
    opacity: 0;
    color: f.$silver;
    font-size: 10px;
  }
}

.edit-button.visible {
  pointer-events: all;

  svg {
    opacity: 1;
  }
}

.documentTable {
  td:nth-of-type(2) {
    color: f.$skyblue;
    font-weight: bold;
    cursor: pointer;
  }
}

td {
  .sticky:nth-child(3) {

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-left: 2px solid f.$skyblue;
      content: '';
    }
  }
}

.sort {
  display: flex;

  p {
    margin: auto;
  }

  .sort-icon {
    margin: auto 0;

    svg {
      font-size: 14px;
    }
  }
}

.openFilter {
  // z-index: 60 !important;
}

.filterList {
  position: absolute;
  min-width: 100px;
  max-height: 100px;
  padding: 8px;
  overflow: scroll;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: f.$white;

  ul {
    display: block;
    height: 100%;
  }

  li {
    margin-bottom: 4px;
    padding: 8px;
    border-radius: 4px;

    &:hover {
      background-color: f.$cultured;
    }
  }
}

.reset-button {
  background-color: f.$gray;
  margin: auto 0;
  padding: 8px 16px;
  border-radius: 16px;
  box-shadow: 0 0 4px f.$gray;
  color: white;
  transition: all ease 300ms;

  &:hover {
    box-shadow: 0 0 2px f.$gray;
  }
}

// @for $i from 4 through $stickyWidth {
//   .sticky:nth-child(#{$i}) {
//     // left: $columnWidth-1 + $stickyWidth + ((2 * $stickyWidth) * ($i - 3));
//     left: calc(#{$i} * 2 * #{$stickyWidth} - #{$i} + px);
//   }
// }

// @for $i from 1 through $max-tableSize {
//   th:nth-last-child(#{$i}),
//   td:nth-last-child(#{$i}) {
//     z-index: $i;
//   }
// }