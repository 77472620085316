// *************** Import *************** //
@use "../../foundations/import" as f;

// *************** Styles *************** //

main {
  .tableWrap {
    table {
      > tbody {
        > tr {
          > td {
            > p {
              &.visa {
                margin-left: 5px;
                padding: 0 3px;
                border: 2px solid f.$navy;
                border-radius: 50%;
                color: f.$navy;
                font-size: 1.2rem;
                font-weight: bold;
              }
            }

            > select,
            > input,
            .datepicker {
              background: transparent;
            }

            &.prohibit {
              background: linear-gradient(
                -15deg,
                f.$navy,
                f.$navy 49%,
                f.$platinum 5%,
                f.$platinum 5%,
                f.$navy 52%,
                f.$navy
              );
              cursor: not-allowed;

              > select,
              > input {
                display: none;
                pointer-events: none;
              }
            }

            .memoButton {
              border: solid 2px transparent;
              border-radius: 16px;
              background: f.$navy;
              color: f.$white;
              font-weight: bold;
              white-space: nowrap;
              cursor: pointer;
              transition-duration: 0.2s;

              &:focus {
                outline: none;
              }

              &:hover {
                border: solid 2px f.$navy;
                background: transparent;
                color: f.$navy;
              }
            }
          }
        }
      }
    }

    // .inputDate {
    //   display: inline-block;
    //   text-align: center;
    //   border: solid 1px transparent;
    //   // width: 110px;
    //   font-size: 12px;

    //   &:hover {
    //     border: solid 1px $red;
    //   }
    // }
    .overdueClient {
      background: f.$pastelRed;
    }

    // inputタグに付与したクラス
    .empty,
    .warning {
      background: f.$pastelYellow;
    }

    .overdue {
      background: f.$pastelRed;
    }

    .disabled {
      background: f.$pastelGray;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .table-button {
    display: flex;
    align-items: center;
    position: fixed;
    right: 5%;
    bottom: 5%;

    > p {
      visibility: hidden;
      opacity: 0;
      color: f.$red;
      font-weight: bold;
      transition: 0.3s;

      &.on {
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }
    }

    > button {
      //margin-left: 15px;
    }
  }

  .memoOverLay {
    //transform: translate(-50%, -50%);
    background: f.$white;
    animation-name: huwaModal;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    .memoWrap {
      box-shadow: 0 2px 16px 0 f.$shadow;

      .memoTitle {
        border-bottom: 2px solid f.$skyblue;
      }

      .memoContents {
        .memoInput {
          flex: 1;
          border: 2px solid f.$skyblue;
          outline: none;
          transition: all 0.3s ease-out;
        }
      }

      > button {
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.253);
      }
    }
  }

  @keyframes huwaModal {
    0% {
      box-shadow: -2px -2px 2px rgba(199, 199, 199, 0), 2px 2px 2px rgba(0, 0, 0, 0);
      filter: blur(8px);
      transform: scale(0.98);
      opacity: 0;
    }
    100% {
      box-shadow: 0 20px 24px rgba(0, 0, 0, 0.13);
      filter: blur(0px);
      opacity: 1;
    }
  }

  // 詳細モーダル
  .detailModal {

    > ul {
      z-index: 100;
      position: relative;
      box-shadow: 0 4px 20px -4px f.$shadow;
      background: f.$white;
      left: 96px;
      > li {
        @include f.toggleSwitch(f.$navy, f.$skyblue, f.$white);
      }
    }
  }
}

.StatusTopHelp {
  h3 {
    color: f.$white;
  }
  .square-red {
    border: solid 8px f.$pastelRed;
    width: 8px;
    height: 8px;
  }
  .square-yellow {
    border: solid 8px f.$yellow;
    width: 8px;
    height: 8px;
  }
  .square-gray {
    border: solid 8px f.$pastelGray;
    width: 8px;
    height: 8px;
  }
  .square-white {
    border: solid 8px f.$white;
    width: 8px;
    height: 8px;
  }
}
