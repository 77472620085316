// 伸び縮みライン
@keyframes extendLine {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  51% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

/*************** Animation  ***************/
// @keyframes slideIn {
//   0% {
//     opacity: 0;
//     transform: translateY(24px);
//   }
//   50% {
//     opacity: 0.5;

//     transform: translateY(16px);
//   }
//   100% {
//     opacity: 1;

//     transform: translateY(0);
//   }
// }

// @keyframes slideOut {
//   0% {
//     opacity: 1;
//     transform: translateY(0);
//   }
//   50% {
//     opacity: 0.5;
//     transform: translateY(-16px);
//   }
//   100% {
//     opacity: 0;
//     transform: translateY(-24px);
//   }
// }
