/*************** Import ***************/
@use "../foundations/import" as f;

.notification {
  position: absolute;
  top: 180px;
  left: 70px;
  width: 300px;
  height: auto;
  max-height: 50vh;
  overflow: scroll;
  border: solid 24px f.$navy;
  border-radius: 16px;
  box-shadow: 0 4px 16px -4px #0d2f473b;
  background-color: f.$navy;
  transition: all ease 300ms;

  &.unvisible {
    opacity: 0;
    transform: translateY(24px);
    pointer-events: none;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  li {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px f.$seaweed;
    color: f.$white;
    text-align: left;
  }
}
