/*************** Import ***************/
@use "../../foundations/import" as f;

/*************** Styles ***************/

body {
  //overflow: scroll;
}
// LP全体
.landingPage {
  overflow: auto;
  background: f.$white;

  @include f.max-screen(f.$breakpoint-tablet) {
    h2 {
      text-align: center;
    }
  }

  // Header
  .lp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 10%;
    padding: 20px;
    background: f.$platinum;
    box-shadow: 0 0 5px 0 f.$gray;

    .titleWrap {

      .title {
        @include f.max-screen(f.$breakpoint-tablet) {
          display: none;
        }
      }
    }
    
    .header-nav {
      
      > ul {
        display: flex;
        
        > li {
          
          &:not(:first-child) {
            margin-left: 20px;
          }

          a {
            display: inline-block;
            border-radius: 5px;
            border: 2px solid transparent;
            background: f.$skyblue;
            color: f.$white;
            font-weight: bold;
            transition: .3s;

            @include f.min-screen(f.$breakpoint-tablet) {
              padding: 10px 15px;
              color: f.$white;
            }

            &:hover {
              border: 2px solid f.$skyblue;
              background: transparent;
              color: f.$skyblue;

              > p {
                color: f.$skyblue;
              }
            }

            > p {
              color: f.$white;
            }
          }

          > .startButton {
            @include f.max-screen(f.$breakpoint-mobile) {
              padding: 5px;
            }
          }

          .linkContent {
            
            > a {
              
              @include f.min-screen(f.$breakpoint-tablet) {
                display: flex;
                align-items: center;
                
                > svg {
                  margin-left: 10px;
                }
              }

              @include f.max-screen(f.$breakpoint-mobile) {
                padding: 5px;
                border-radius: 50%;
                background: transparent;
                text-align: center;

                > svg {
                  display: block;
                  color: f.$skyblue;
                }

                > p {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    @include f.max-screen(f.$breakpoint-tablet) {
      .loginInfo {
        //background: transparent;

        .loginUser {

          > p {
            display: none;
          }

          .accountButton {
            margin: 0;
          }
        }
      }
    }
  }
  
  // Main
  .lp-main {
    padding: 3%;
    @include f.min-screen(f.$breakpoint-pc) {
      padding: 0 10%;
    }
    // section共通
    > section {
      margin-top: 20%;
    }
    
    // トップラップ
    .topWrap {
      display: flex;
      justify-content: space-between;
      padding-top: 20%;

      @include f.max-screen(f.$breakpoint-tablet) {
        padding: {
          top: 15%;
          right: 5%;
          left: 5%;
        }
        flex-direction: column-reverse;
      }
      
      .topLeft {
        width: 50%;

        @include f.max-screen(f.$breakpoint-tablet) {
          width: 100%;
          margin-top: 10%;
          text-align: center;
        }

        .topTitle {

          > h1 {

            .title {
              width: 40%;
            }
          }
          
          > h2 {
            margin-top: 3%;

            .title {
              width: 25%;
            }
          }
        }

        .topDetail {

          &:first-child {
            > p {
              margin-top: 5%;
            }
          }

          > p {
            .title {
              width: 12%;
            }
          }
        }
      }

      .topImage {
        flex: 1;

        @include f.max-screen(f.$breakpoint-tablet) {
          width: 100%;
        }

        > img {
          object-fit: cover;
          height: 100%;
        }
      }
    }

    // 使い方紹介
    .aboutConnect {

      .about {

        @include f.min-screen(f.$breakpoint-pc) {
          display: flex;
          justify-content: space-between;
        }

        > ul {
          position: relative;
          
          > li {
            width: 100%;
          }

          // タイトル側
          &.titleLists {
            left: 0;

            @include f.max-screen(f.$breakpoint-tablet) {
              display: none;
            }

            > li {

              &:not(:first-child) {
                margin-top: 1.5%;
              }

              .listTitle {
                display: inline-flex;
                position: relative;
                top: 0;
                left: 0;
                cursor: pointer;

                > p {
                  font-size: 2.1rem;
                  white-space: nowrap;
                }
                
                &.active, &:hover {
                  
                  > p {
                    opacity: 1;
                    
                    &:first-child {
                      color: f.$skyblue;
                    }
                  }
                }
                
                > p {
                  opacity: .5;
                  font-weight: bold;
                  transition: .3s;
                  
                  &:nth-child(2) {
                    margin-left: 20px;
                  }
                }
              }
            }
          }
          
          // イメージ側
          &.imageLists {
            right: 0;
            width: 65%;
            margin-left: 3%;

            @include f.max-screen(f.$breakpoint-tablet) {
              width: 100%;
              margin: 0;
            }
            
            > li {
              display: none;
              visibility: hidden;
              
              &.active {
                display: block;
                visibility: visible;
                // opacity: 1;
              }
              
              .aboutContents {
                // opacity: 0;
                position: relative;
                top: 0;
                right: 0;
                transition: .3s;

                .aboutImage {
                  width: 100%;
                  padding: 3%;
                  border-radius: 10px;
                  background: f.$platinum;
                  box-shadow: 0 2px 15px -6px f.$black;
                }
                
                > p {
                  margin-top: 20px;
                  color: f.$navy;
                  font-weight: bold;
                  text-align: left;
                }
              }
            }
          }
        }
      }

      // 矢印
      .buttonWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3%;

        .arrowWrap {
          display: flex;

          .circle {
            padding: 12px 14px;
            border: 2px solid transparent;
            border-radius: 50%;
            background: f.$navy;
            color: f.$white;
            transition: .3s;
            cursor: pointer;

            &:nth-child(2) {
              margin-left: 5px;
            }

            &:hover {
              border: 2px solid f.$navy;
              background: transparent;

              > svg {
                color: f.$navy;
              }
            }

            > svg {
              transition: .3s;
            }
          }
        }
      }
    }

    // CONNECTへ遷移するsection
    .connectWrap {
      text-align: right;

      @include f.max-screen(f.$breakpoint-tablet) {
        text-align: center;
      }
      
      > ul {
        padding: 5% 0;
        border: 3px solid f.$skyblue;
        border-radius: 10px;
        text-align: center;
        
        @include f.min-screen(f.$breakpoint-pc) {
          display: flex;
          justify-content: space-around;
          //align-items: center;
        }

        > li {
          @include f.max-screen(f.$breakpoint-tablet) {
            &:not(:first-child) {
              margin-top: 15%;
            }
          }

          > h3 {
            margin-top: 10%;
          }

          > h4 {
            margin-top: 3%;
          }
          
          > p {
            margin-top: 3%;
          }

          > a {
            display: inline-block;
            border: 2px solid transparent;
            border-radius: 40px;
            background: f.$navy;
            color: f.$white;
            font-weight: bold;
            text-align: center;
            transition: .3s;

            &:hover {
              border: 2px solid f.$navy;
              background: f.$white;
              color: f.$navy;
            }
          }
        }
      }
    }

    // 他サイトリンクへ促すsection
    .providerWrap {

      @include f.max-screen(f.$breakpoint-tablet) {
        text-align: center;
      }

      .wrapFlex {

        .providerContents {
          margin-top: 2%;
          margin-left: 5%;
          flex: 1;

          > p {
            margin-top: 5%;
          }
        }

        @include f.min-screen(f.$breakpoint-pc) {
          display: flex;

          > p {
            width: 50%;
          }
        }
      }

      > ul {
        margin-top: 8%;

        @include f.min-screen(f.$breakpoint-pc) {
          display: flex;
          justify-content: space-around;
        }
        
        > li {
          position: relative;
          border: 3px solid f.$skyblue;
          border-radius: 10px;
          
          @include f.max-screen(f.$breakpoint-tablet) {
            &:not(:first-child) {
              margin-top: 10%;
            }
          }

          @include f.min-screen(f.$breakpoint-pc) {
            width: 30%;
          }

          .sectionImage {
            border-radius: 10px;
            background: f.$navy;

            > img {
              display: block;
              width: 100%;
              height: 200px;
              background: f.$pureWhite;
              object-fit: contain;
            }
          }

          > h3 {
            text-align: center;
          }

          > h3 {
            margin-top: 5%;
          }

          .sectionDetail {
            margin-top: 10%;

            > p {
              word-wrap: break-word;

              &:last-child {
                margin-top: 5%;
              }
            }
          }

          > a {
            //position: absolute;
            //width: 60%;
            padding: 10px ;
            border: 2px solid transparent;
            border-radius: 40px;
            background: f.$navy;
            color: f.$white;
            font-weight: bold;
            text-align: center;
            transition: .3s;

            &:hover {
              border: 2px solid f.$navy;
              background: f.$white;
              color: f.$navy;
            }
          }
        }
      }
    }

    .introduceWrap {
      // margin-top: 10%;

      .swiper-container {
        width: 70%;
      }
      
      .slideImages {
        margin-top: 5%;
        text-align: center;
      }
    }
    
    // スタートボタン
    .startButton {
      display: inline-block;
      margin-top: 5%;
      padding: 15px 20px;
      border: 2px solid transparent;
      border-radius: 5px;
      background: f.$skyblue;
      color: f.$white;
      font-weight: bold;
      transition: .3s;

      &:hover {
        border: 2px solid f.$skyblue;
        background: transparent;
        color: f.$skyblue;
      }
    }
  }
  
  // Footer
  .lp-footer {
    margin-top: 3%;
    padding: 3%;
    background: f.$platinum;
    
    .footerContents {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .footerTitle {
        display: flex;
      }
      
      > ul {
        display: flex;
        justify-content: space-between;
        // width: 25%;

        > li {

          > a {
            opacity: .5;
            padding: 5px;
            transition: .3s;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    
    .companyWrap {
      margin-top: 5%;
      text-align: center;
    }
  }

  // ロゴ
  .logo {
    width: 35px;
    height: 35px;
    margin: 0;
    padding: 3px 5px;
    border-radius: 8px;
    background: f.$navy;
  }
  // タイトル
  .title {
    width: 120px;
  }

  // タイトル & ロゴ
  .titleWrap {
    display: flex;
    align-items: center;

    .title {
      margin-left: 10px;
    }
  }
}