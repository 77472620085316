/*************** Color ***************/
$navy: #0d2f47;
$sapphire: #0f435a;
$seaweed: #157c93;
$skyblue: darken(#1dc9df, 2%);
$gray: #8b8b8b;
$pureWhite: #fff;
$white: #fafafa;
$black: #000000;
$shadow: #0d2f473b;
$memo: #17324636;
$jet: #303030;
$lightGray: #cacaca;
$pastelGray: #cccccc;
$silver: #a0a0a0;
$cultured: #f0f2f4;
$platinum: #e8e8e8;
$red: #e23636;
$pastelRed: #ff6666;
$vermilion: #fd9585;
$orange: #e29336;
$green: #28bf28;
$purple: #d436e2;
$yellow: #fff50c;
$pastelYellow: #ffff77;