/*************** Import ***************/
@use "../../foundations/import" as f;

.errorComponent {
  box-sizing: border-box;

  .errorContent {
    > pre {
      color: f.$red;
    }

    p {

      a {
        display: inline;
        color: f.$seaweed;
      }
    }
  }
}

.errorMessage {
  color: f.$pastelRed;
}
