// *************** Import *************** //
@use "../../foundations/import" as f;

main {
    // マスターデータ登録フォーム
    &.HiredInfo {
        background: f.$white;
        animation-name: huwaModal;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        > h2 {
            @include f.max-screen(f.$breakpoint-tablet) {
                font-size: 25px;
            }
        }

        .hiredWrapper {
            background-color: f.$skyblue;

            .companyDetails {

                .empInformation {

                    .clientInfo {
                        > p {
                            border-left: thick solid f.$seaweed;
                        }
                    }

                    > ul {
                        > li {
                            border-left: thick solid f.$seaweed;
                        }
                    }
                }
            }

            .hiredDetails {
                //background-color: f.$white;

                .companyList {
                    > li {
                        &:not(:first-child) {
                            margin-top: 3%;
                        }
                    }
                }

                .v_line_fix {
                    border-left: thick solid f.$seaweed;
                    padding-left: 10px;

                    .webLink {
                        transition: .3s;

                        &:hover {
                            color: f.$skyblue;
                        }
                    }
                }
            }
        }

        .hiredCompanyNumberWrapper {
            box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
            0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

            .empInformation {

                > p {
                    border-left: thick solid f.$seaweed;
                }
            }
        }
    }

    @keyframes huwaModal {
        0% {
            transform: scale(0.98);
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
