// *************** Import *************** //
@use "../../foundations/import" as f;

// *************** Styles *************** //
main {

  &.topPage {
    height: 100vh;
    
    h4 {
      color: f.$gray;
    }
  }
  
  .pageContents {

    // メニューセクション
    .menuWrap {

      .menuLists {
        margin-bottom: 20px;

        .menuList {
          width: auto;
          margin: 0 24px 20px 0;
          cursor: pointer;
          box-shadow: 0 2px 8px f.$shadow;
          border-radius: 8px;
          transition: all ease 300ms;
          
          &:hover {
            box-shadow: 0 2px 12px f.$shadow;
            transform: translateY(-2px);
          }
          
          > a {
            display: flex;
            min-width: 200px;
            padding: 25px;
            text-align: center;
          }
          
          &-0 {
            background: linear-gradient(135deg, #1dc9df 0%, #8ee4ef 100%);
            .menuList-icon svg {
              color: #1dc9df;
            }
          }
          &-1 {
            background: linear-gradient(135deg, #de4b33 0%, #efa599 100%);
            .menuList-icon svg {
              color: #de4b33;
            }
          }
          &-2 {
            background: linear-gradient(135deg, #de9907 0%, #efcc83 100%);
            .menuList-icon svg {
              color: #de9907;
            }
          }
          &-3 {
            background: linear-gradient(135deg, #15de12 0%, #8aef89 100%);
            .menuList-icon svg {
              color: #15de12;
            }
          }
          &-4 {
            background: linear-gradient(135deg, #ab28de 0%, #d594ef 100%);
            .menuList-icon svg {
              color: #ab28de;
            }
          }
          &-5 {
            background: linear-gradient(135deg, #ff3399 0%, #ff3399 100%);
            .menuList-icon svg {
              color: #ff3399;
            }
          }
          
          .menuList-icon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background-color: f.$white;
            margin-right: 8px;
            
            svg {
              margin: auto;
            }
          }
          
          .menuList_link {
            color: f.$white;
            margin: auto 0;
          }
        }
      }
    }
      
    > .pageContents-wrap {
      margin-top: 20px;
    }

    .countClient {

      > li {
        background: f.$white;
      }
    }
  }

  // 合計数セクション
  .countNumberWrap {
    margin-bottom: 40px;
    padding: 24px;
    display: inline-block;
    
    .countNumber {
      li {
        min-width: 180px;
        padding: 8px;
        margin: 10px 8px;
        
        @mixin countTypeColor($color) {
          h1 {
            border-bottom: 2px solid $color;
          }
          .updown {
            color: $color;
          }
        }
        
        &:nth-of-type(1) {
          @include countTypeColor(#ffc347);
        }
        &:nth-of-type(2) {
          @include countTypeColor(#b6a8f7);
        }
        &:nth-of-type(3) {
          @include countTypeColor(#95eb65);
        }
        &:nth-of-type(4) {
          @include countTypeColor(#f7ad77);
        }
        &:nth-of-type(5) {
          @include countTypeColor(#614ecc);
        }
        
        h1 {
          display: inline-block;
          margin-bottom: 8px;
        }
        
        .updown {
          padding-left: 4px;
        }
        
        p {
          color: f.$gray;
        }
      }
    }
  }

  // ドキュメントセクション
  .pageContents_flex {
    box-sizing: border-box;

    h5 {
      margin-bottom: 24px;
    }
    
    h2 {
      margin: auto 2px 0 0;
    }
    
    p.font_12 {
      margin: auto 0 0 0;
      line-height: 2.4rem;
    }
    
    p.font_10 {
      margin-top: 8px;
      color: f.$silver;
    }
    
    .pageContents-wrap {
      height: 100%;
      margin-right: 24px;
      padding: 24px;
      box-sizing: border-box;
      background-color: f.$white;
      
      &.contents_apply,
      &.contents_deadline {
        margin-bottom: 40px;
        
        .pageContents-icon {
          width: 32px;
          height: 32px;
          border-radius: 100%;
          margin-bottom: 24px;
          
          svg {
            width: 12px;
            margin: auto;
          }
        }
      }

      &.contents_apply {
        .pageContents-icon {
          background: rgb(216, 250, 255);
          svg {
            color: #157c93;
          }
        }

        h5 {
          color: #157c93;
        }
      }

      &.contents_deadline {

        .pageContents-icon {
          background: #ffedea;
          svg {
            color: #de4b33;
          }
        }
        
        h5 {
          color: #de4b33;
        }
      }

      &.contents_news {
        min-width: 600px;
        transition: all ease 300ms;
        transition-delay: 300ms;
        
        &.open {
          position: relative;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transition: all ease 300ms;
          
          .wrapContent {
            max-height: 50vh;
            overflow-y: scroll;
            transition: all ease 300ms;
            transition-delay: 300ms;
          }
          
          .closeButton {
            cursor: pointer;
          }
        }
        
        p.font_10 {
          color: f.$seaweed;
        }
        
        .wrapContent {
          margin: 24px 0;
          background-color: f.$cultured;
          padding: 8px 16px;
          max-height: 138px;
          border-radius: 8px;
          overflow-y: hidden;
          transition: all ease 300ms;
          
          p {
            margin-bottom: 8px;
            padding: 8px 4px;
            border-bottom: 1px solid f.$white;
          }
        }
        
        .moreButton {
          margin-top: 16px;
          text-align: center;
          color: f.$skyblue;
          cursor: pointer;
          transition: all ease 300ms;
          
          &:hover {
            transform: translateY(-2px);
          }
        }
      }
    }
  }

  // 未承認ページ
  &.notApproval {

    .messageWrap {

      .messageTitle {
        color: f.$skyblue;
      }

      strong {
        border-bottom: dashed f.$skyblue 3px;
      }
    }
  }
}