@use "../../foundations/import" as f;

.HelpProvider {
    cursor: help;
    .dot-button {
        padding: 0.35em 0.64em;
        text-decoration: none;
        color: #818181;
        border: dashed 1px #8d8d8d;
        background: #f2fcff;
        border-radius: 32px;
        transition: 0.4s;
        //margin-left: 1em;
    }

    .dot-button:hover {
        background: #cbedff;
        color: #fff;
        border: solid 1px #8d8d8d;
    }
    .help-top {
        position: absolute;
        margin: 36px 16px;
        padding: 16px 8px;
        font-size: 24px;
        font-weight: bold;
        background: f.$skyblue;
        border-radius: 16px;
        box-sizing: border-box;
        z-index: 100;
        // anime
        animation-name: huwaModal;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        .textWrapper {
            margin: 8px 8px;

            .v_line_fix {
                border-left: thick solid f.$seaweed;
                padding-left: 10px;
                > h2 {
                    margin-bottom: 8px;
                    color: f.$white;
                }
            }
        }
    }
    .help-top:before {
        content: "";
        position: absolute;
        top: -24px;
        left: 32px;
        margin-left: -16px;
        border: 8px solid transparent;
        border-bottom: 16px solid f.$skyblue;
        z-index: 0;
    }
    @keyframes huwaModal {
        0% {
            transform: scale(0.98);
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
