/*************** Import ***************/
@use "../foundations/import" as f;

html,
body,
#root,
.App .whole {
  height: 100vh;
}

body {
  overflow: hidden;
  
  .App {
    
    .whole {
      display: flex;
      background-color: f.$white;

      .contents {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        width: 100%;
        height: 100%;
        min-width: 0;
        min-height: 0;
        
        main {
          position: relative;
          width: 100%;
          height: 100%;
          min-width: 0;
          min-height: 0;
          overflow-y: scroll;
          -ms-overflow-style: none;    /* IE, Edge 対応 */
          scrollbar-width: none;       /* Firefox 対応 */

          &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
            display:none;
          }

          &:not(.lp-main) {
            padding: 2%;
          }
        }
      }
    }
  }
}
