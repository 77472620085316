/*************** Import ***************/
@use "../../foundations/import" as f;

/*************** Styles ***************/
// POLO申請書ページ
.poloDocContent {

  &-main {

    .searchWrap {
      border: solid 3px f.$skyblue;
    }

    .documentWrap {
      box-shadow: 2px 2px 5px f.$shadow;
      background: f.$cultured;
      overflow-y: scroll;

      > h2 {
        color: f.$skyblue;
      }

      .visaCategory {

        .visaList {
          box-shadow: 2px 2px 5px f.$shadow;
          background: f.$white;

          &:not(:first-child) {
            margin-top: 2%;
          }

          .allDocuments {
            visibility: hidden;
            opacity: 0;
            max-height: 0;
            transition: .8s;
            transition-delay: .1s;

            &.open {
              visibility: visible;
              opacity: 1;
              max-height: 100vh;
              overflow-y: scroll;
            }

            > li {

              > a {
                border: 3px solid transparent;
                background: f.$navy;
                color: f.$white;
                transition: .3s;

                &:hover {
                  border: 3px solid f.$navy;
                  background: transparent;
                  color: f.$navy;
                }
              }
            }
          }
        }
      }
    }
  }

  .partnerWrap {
    width: 20%;
    margin-left: 2%;
    padding: 20px;
    border: solid 3px f.$skyblue;
    border-radius: 5px;

    .wrapContent {
      display: flex;
      flex-direction: column;
      height: 100%;

      .partnerList {
        height: 50%;
        padding: 20px;
        border-radius: 3px;
        background: f.$cultured;

        &:not(:first-child) {
          margin-top: 20px;
        }

        .selectSet {

          > select {
            width: 100%;
          }
        }
      }
    }

    .buttonWrap {
      //margin-top: 10px;

      margin: {
        top: 10px;
        right: auto;
        left: auto;
      }
    }
  }
}