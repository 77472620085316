/*************** Import ***************/
@use "../foundations/import" as f;

// *************** Styles *************** //
.header {
  background: f.$skyblue;

  .pankuzuWrap {

    > ul {
      align-items: center;

      > li {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-left: 10px;
        }

        > a, > p, > svg {
          display: block;
          color: f.$white;
        }

        > a, > p {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .poloButton {
    white-space: nowrap;
  }

  .tabMenu {
    display: flex;
    justify-content: flex-end;
    padding-right: 1%;
    background-color: f.$skyblue;

    .tabList {
      display: flex;
      align-items: center;
      border-radius: 8px 8px 0 0;
      color: f.$white;
      cursor: pointer;
      transition: all ease .3s;

      &:not(.active):hover {
        background: f.$white;
        color: f.$skyblue;
      }
    }
  
    .active {
      transform: scaleY(1);
  
      background-color: f.$white;
      color: f.$skyblue;
  
      > p {
        color: f.$skyblue;
      }
    }
  }

  @include f.max-screen(f.$breakpoint-mobile) {
    display: none;
  }
}

// ヘルプページなどのheader
.staticHeader {
  padding: 24px 40px;
  background: f.$skyblue;

  .siteLogo {
    margin: auto 0;

    svg {
      height: 24px;
    }
  }

  .headerNav {
    margin: auto 0;

    ul {
      li {
        &:not(:last-child) {
          margin-right: 8px;
        }

        a {
          color: white;
        }
      }
    }
  }
}

// パンくずリスト
.breadcrumbs {

  a {

    > span {
      color: f.$white;
    }

    &.active {
      font-weight: bold;
    }
  }
}

/*************** Account Button ***************/
.accountButton {
  border: solid 1px transparent;
  background: f.$skyblue;
  color: f.$white;
  transition: .3s;

  &:hover {
    border: solid 1px f.$white;
    background: transparent;
    color: f.$white;
  }
}
