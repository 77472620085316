// *************** Import *************** //
@use "../../foundations/import" as f;

// *************** Styles *************** //
.loginContent {

  .loginForm {

    .formList {

      &:not(:first-child) {
        margin-top: 40px;
      }

      &:hover {
        + .formInput .inlineItems > i {
          color: f.$skyblue;
        }
      }

      > li {

        &:not(:first-child) {
          margin-top: 2rem;
        }

        .inputSet {

          > input {
            border: solid 2px f.$navy;
            background: f.$white;
            outline: none;
            transition: .3s;

            &:focus {
              border: solid 2px f.$skyblue;
              box-shadow: f.$skyblue 0 0 5px;

              + .inlineItems > i {
                color: f.$skyblue;
              }
            }
          }

          .inlineItems {
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 5%;
            transform: translateY(-50%);

            > i {
              transition: .3s;
            }

            > label {
              margin-left: 15px;
            }
          }
        }

        .passwordEye {
          position: absolute;
          top: 50%;
          right: 2%;
          transform: translateY(-50%);
        }
      }

      // signUp用
      > ul {
        display: flex;
        justify-content: center;
        
        > li {
          display: inline-flex;
          align-items: center;
          
          &:not(:first-child) {
            margin-left: 1.5%;
          }

          > label {
            margin-left: 3px;
          }

          // input[type="checkbox"]:checked {
          //   color: $skyblue;
          // }
      
          // .formStock {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   margin-top: 30px;
      
          //   > input {
          //     display: block;
          //   }
      
          //   p {
          //     margin-left: 15px;
          //   }
          // }
        }
      }

      .errorMessage {
        margin-top: 1%;
        color: f.$red;
        font-weight: bold;
      }

      > p {
        font-weight: bold;
      }
    }

    button {
      margin-top: 40px;
    }

    .forgetPass {
      margin-top: 20px;
      text-decoration: underline;
    }
  }

  // ユーザーセレクト
  .userTypeList {

    > li {

      > label {
        background: f.$navy;

        > p {
          color: f.$white;
        }
      }
    }
  }

  .authLink {
    text-decoration: underline;
    transition: .2s;

    &:hover {
      color: f.$skyblue;
    }
  }

  .switchButton {
    display: inline-block;
    position: absolute;
    top: 5%;
    right: 3%;

    @include f.max-screen(f.$breakpoint-tablet) {
      display: none;
    }
  }

  .errorMessage {
    color: f.$red;
    font-weight: bold;
  }
}