// *************** Import *************** //
@use "../../foundations/import" as f;

main {
    // マスターデータ登録フォーム
    &.applyCompany {
        > h2 {
            @include f.max-screen(f.$breakpoint-tablet) {
                font-size: 25px;
            }
        }

        .applyWrapper {
            margin: 48px auto 48px auto;
            width: 84vw;
            height: 64vh;
            background-color: f.$skyblue;
            padding: 4vh 8vh 4vw 8vh;
            border-radius: 2rem;

            p {
                color: f.$white;
            }

            .v_line_fix {
                border-left: thick solid f.$seaweed;
                padding-left: 10px;
            }

            .postButton {
                width: 100%;
                height: 8vh;
                text-align: center;
            }
        }
    }
}
