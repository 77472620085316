// *************** Import *************** //
@use "../../foundations/import" as f;

.masterCatalogOverLay {
  overflow-y: scroll;
  background: f.$white;
  animation-name: huwaModal;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  box-shadow: 0 4px 20px -4px f.$shadow;

  .masterCatalogTitle {
    border-bottom: 2px solid f.$skyblue;
  }

  .masterCatalogInput {
    flex: 1;
    border: 2px solid f.$skyblue;
    outline: none;
    transition: all 0.3s ease-out;
  }

  .masterCatalogButton {
    flex: 1;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.253);
  }

}

@keyframes huwaModal {
  0% {
    box-shadow: -2px -2px 2px rgba(199, 199, 199, 0), 2px 2px 2px rgba(0, 0, 0, 0);
    filter: blur(8px);
    transform: scale(0.98);
    opacity: 0;
  }
  100% {
    box-shadow: 0 20px 24px rgba(0, 0, 0, 0.13);
    filter: blur(0px);
    opacity: 1;
  }
}


// *************** Styles *************** //
main {
  // マスター一覧ページ
  &.masterCatalog {

       .modalList {
        z-index: 100;
        box-shadow: 0 4px 20px -4px f.$shadow;
        background: f.$white;

        > li {
          @include f.toggleSwitch(f.$navy, f.$skyblue, f.$white);
        }
      }
    //}
  }

  // マスター管理個別ページ
  &.detailsWrap {
    .topButtonWrap {
      .toMasterButton {
        border: solid 2px transparent;
        border-radius: 5px;
        background: f.$navy;
        color: f.$white;
        transition: all ease 0.3s;

        &:hover {
          border: solid 2px f.$navy;
          background-color: f.$white;
          color: f.$navy;
        }

        > svg {
          margin-right: 10px;
        }

        @include f.max-screen(f.$breakpoint-tablet) {
          position: static;
          margin: {
            top: 10%;
            right: auto;
            left: auto;
          }
        }
      }
    }

    .detailsContent {
      border: solid 5px f.$skyblue;
      border-radius: 5px;

      > li {
        display: flex;
        justify-content: space-between;

        &:not(:first-child) {
          border-top: solid 2px f.$skyblue;
        }

        > p {
          padding: 5%;
          text-align: center;

          &.key {
            width: 40%;
            background-color: f.$platinum;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.value {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.url {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-height: 320px;
              width: auto;
            }

            a {
              margin-top: 8px;
            }
          }
        }
      }
    }

    .userImageList {
      > li {
        &:not(:first-child) {
          margin-top: 3rem;
        }
      }
    }

    .nonData {
      padding: 20px;
      border: dashed 3px f.$skyblue;

      > p {
        font-weight: bold;
      }
    }

    .cancelButton {
      display: inline-block;
      margin-top: 5%;
    }
  }

    // マスターデータ登録フォーム
    &.masterChange {
      > h2 {
        @include f.max-screen(f.$breakpoint-tablet) {
          font-size: 25px;
        }
      }

      .masterFlex {
        @include f.min-screen(f.$breakpoint-pc) {
          width: 800px;
        }
        box-shadow: 0 4px 20px -4px f.$shadow;

        .formTab {

          > li {

            > a {

              &.active {
                background: f.$skyblue;
                color: f.$white;
                font-weight: bold;
              }
            }
          }
        }

        label {
          border-bottom: 3px solid f.$skyblue;
        }

        input,
        select {
          width: 100%;
          padding: 5px 10px;
          border: 2px solid f.$red;
        }

        .selectInput {
          border: solid 2px f.$red;

          .css-yk16xz-control,
          .css-1pahdxg-control {
            border: none;
            border-radius: 0;
            box-shadow: none;
          }

          // 未Focus時
          .css-1hb7zxy-IndicatorsContainer {
            svg {
              opacity: 0.5;
              color: f.$skyblue;
              transition: 0.3s;
            }
          }

          // Focus時
          .css-1pahdxg-control {
            svg {
              opacity: 1;
              transform: rotate(180deg);
            }
          }
        }

        .formList {

          > li {
            &:not(:first-child) {
              margin-top: 20px;
            }

            > p {
              margin-top: 10px;
            }
          }

          &.sysKeySelect {
            display: inline-flex;
            flex-direction: column;

            > h3 {
              margin-top: 5%;
            }
          }
        }
      }

      .buttonWrap {
        text-align: center;
      }

      .submitMessage {
        visibility: hidden;
        opacity: 0;
        margin-top: 30px;
        color: f.$red;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        transition: 0.1s;

        &.on {
          visibility: visible;
          opacity: 1;
        }
      }

      // @include max-screen($breakpoint-tablet) {
      //   width: 100%;
      // }
      .masterForm-image-wrapper {
        .formSet {
          margin-top: 3%;
          border: 2px solid f.$red;
        }

        .masterForm-image {
          border: 2px dashed f.$skyblue;
          border-radius: 5px;

          input[type="file"] {
            display: none;
          }
        }

        .UploadImages {
          flex-wrap: wrap;

          .UploadImages-item {
            .imageBlock {
              border-radius: 5px;
              border: 5px solid f.$navy;
            }

            .cancelButton {
              width: 64px;
              min-width: 64px;
              height: 24px;
              padding: 0;
            }

            @include f.max-screen(f.$breakpoint-mobile) {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }

      .buttonWrap {
        margin-top: 40px;

        > a {
          display: inline-block;
        }

        > button {
          margin-left: 20px;
        }
      }

      .variable {
        margin-bottom: 24px;
        margin-right: 16px;

        label {
          width: 100%;
          display: block;
        }

        input {
          margin: 8px 0;
          padding: 4px;
          width: 300px;
          box-sizing: border-box;
          border-bottom: 1px solid f.$silver;

          &:focus {
            border-bottom: 1px solid f.$skyblue;
          }
        }
      }
    }
}
