// *************** Import *************** //
@use "../../foundations/import" as f;

.loading-table {
  width: 100%;
  height: 120px;
  margin-bottom: 16px;
}

// .loading-search {
//   width: 300px;
//   height: 40px;
//   border-radius: 24px;
//   margin-right: 8px;
//   margin-bottom: 16px;
// }

// .loading-filter {
//   width: 40px;
//   height: 40px;
//   border-radius: 40px;
//   margin-bottom: 16px;
// }

.movingShade {
  background-size: 200% 200% !important;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: movingShade;
  animation-name: movingShade;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: f.$gray;
  background-image: linear-gradient(to right, f.$gray 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
  background-repeat: no-repeat;
}

@keyframes movingShade {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
@-webkit-keyframes movingShade {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
