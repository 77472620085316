// *************** Import *************** //
@use "../../foundations/import" as f;

// *************** Styles *************** //
.UserSelectTitle {
    border-bottom: 2px solid f.$skyblue;
}
.effectwrap {
    .UserSelectOverLay {
        background: f.$white;
        animation-name: huwaModal;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        .buttonWrapper {
            margin-bottom: 30px;
        }
    }

    background: f.$white;
    animation-name: huwaModal;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    @keyframes huwaModal {
        0% {
            transform: scale(0.98);
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
