/*************** Import ***************/
@use "../../foundations/import" as f;


/*************** Styles ***************/
.docInput {
  border-bottom: 1px solid f.$silver;
}

/*************** All Document ***************/
main {

  .docButtonWrap {
    margin-bottom: 20px;

    > a {
      display: inline-flex;
      align-items: center;
      padding: 10px 15px;
      border: solid 2px transparent;
      border-radius: 5px;
      background: f.$skyblue;
      color: f.$white;
      font-weight: bold;
      transition: .3s;
      
      &:hover {
        border: solid 2px f.$skyblue;
        background: transparent;
        color: f.$skyblue;

        > svg, > p {
          color: f.$skyblue;
        }
      }

      > p {
        margin-left: 10px;
        color: f.$white;
      }
    }
  }

  .documentsMenu {

    .docCategory {
      transition: .5s;

      // カテゴリーリスト
      .categoryList {
        box-shadow: 2px 2px 5px f.$shadow;
        transition: .5s;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 2px 12px #0d2f473b;
        }

        .docGroup {
          transition: .5s;

          &.open {
            visibility: visible;
            opacity: 1;
            overflow-y: scroll;
            max-height: 100vh;
          }

          .groupList {
            transition: .5s;

            > h4 {
              background: f.$navy;
            }
          }
        }

        .docFields {
          transition: .5s;

          &.open {
            visibility: visible;
            opacity: 1;
            max-height: 100vh;
            overflow-y: scroll;
          }

          // 分野リスト
          .fieldsList {
            transition: .5s;

            > p {
              border: solid 2px transparent;
              border-radius: 5px;
              background: f.$navy;
              color: f.$white;
              cursor: s-resize;
              transition: .3s;

              &:hover {
                border: solid 2px f.$navy;
                background: f.$white;
                color: f.$navy;
                font-weight: bold;
              }
            }

            // 申請書リスト
            .docList {
              overflow-y: scroll;
              background: f.$platinum;
              transition: .5s;

              &.open {
                visibility: visible;
                opacity: 1;
                max-height: 100vh;
              }
            }
          }
        }
      }
    }

    // 技能実習のみ
    .docStep {

      .stepList {
        display: flex;
        flex-direction: column;
        padding: 2%;
        border-radius: 8px;
        box-shadow: 2px 2px 5px f.$shadow;
        background: f.$cultured;
        transition: .8s;

        &:not(:first-child) {
          margin-top: 2%;
        }

        > h3 {
          color: f.$skyblue;
        }

        .docCategory {
          //visibility: hidden;
          //opacity: 0;
          //max-height: 0;

          //&.open {
          //  visibility: visible;
          //  opacity: 1;
          //  height: 100%;
          //  max-height: 100rem;
          //}

          .categoryList {
            background: f.$white;
          }
        }
      }
    }
  }

  // POLO企業一覧
  .companiesList {
    
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      > li {
        border-radius: 8px;
        box-shadow: 2px 2px 5px f.$shadow;
        cursor: pointer;
        transition: .3s;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 2px 12px f.$shadow;

          > p {
            color: f.$skyblue;
          }
        }
        
        > p {
          padding: 15px;
          transition: .3s;
        }
      }
    }
  }

  // モーダル内要素
  .poloLists {
    position: relative;
    z-index: 100;
    height: 60vh;
    padding: 5%;
    overflow: scroll;
    border-radius: 5px;
    background: f.$platinum;
    
    > li {

      &:not(:first-child) {
        margin-top: 15px;
      }

      > a {
        padding: 1% 2%;
        border: solid 2px transparent;
        border-radius: 5px;
        background: f.$navy;
        color: f.$white;
        font-weight: bold;
        transition: .3s;

        &:hover {
          border: solid 2px f.$navy;
          background: transparent;
          color: f.$navy;
        }
      }
    }
  }
}

/*************** Document Form ***************/
.docForm {
  padding: 10% 5%;
  background: f.$white;

  &.on {
    overflow: hidden;
  }

  .docFormContent {

    .excelForm {

      .formContent {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        box-shadow: 0 0 24px f.$shadow;

        .swiperImage {
          flex: 1;
          position: -webkit-sticky;
          position: sticky;
          top: 3%;
          width: 100%;
          height: 60%;
          margin-left: 2%;
          text-align: center;

          .swiper-button-next, .swiper-button-prev {
            color: f.$skyblue;
          }

          .swiper-pagination {
            top: .5%;

            &-bullet {
              width: 10px;
              height: 10px;
              background: f.$skyblue;
            }
          }
        }
        // しきたりの縦線
        .splitLine {
          width: 5px;
          margin: 0 2%;
          background: f.$navy;
          content: '';
        }

        > ul {
          width: 50%;
          height: 100%;

          > li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 5%;
            border: solid 1px f.$navy;
            transition: .3s;

            &:hover {
              border: solid 1px transparent;
              box-shadow: 0 0 8px 3px lighten(f.$black, 80%);
            }

            &:not(:first-child) {
              margin-top: 8%;
            }

            .inputHead {
              display: flex;
              justify-content: center;
              align-items: center;

              > label {
                display: inline-block;
                position: relative;
                margin-left: 15px;
                // border-bottom: 3px solid $skyblue;
                text-decoration: none;

                &:after {
                  position: absolute;
                  bottom: -5px;
                  left: 0;
                  transform: scale(0, 1);
                  transform-origin: right top;
                  width: 100%;
                  height: 3px;
                  background: f.$skyblue;
                  content: '';
                  transition: transform .5s;

                }

                &:hover::after {
                  transform: scale(1, 1);
                  transform-origin: left top;
                }
              }
            }

            > input {
              width: 100%;
              padding: 4px 8px;
              border: solid 1px f.$skyblue;
              background-color: f.$cultured;
              pointer-events: auto;
              transition: .3s;

              &:not(:first-child) {
                margin-top: 5%;
              }

              &:focus,
              &:hover {
                border: solid 1px transparent;
                background-color: f.$seaweed !important;
                color: f.$white;

                &.inputHead {

                  > label {
                    &:after {
                      transform: scale(1, 1);
                      transform-origin: left top;
                    }
                  }
                }
              }

              &.sysKey {

                &::placeholder {
                  color: f.$white;
                }

              }
            }

            > p {
              margin-top: 5%;
              text-align: left;
            }

            .errorMessage {
              color: f.$red;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .docForm-title {
    margin: 30px auto 60px auto;
    text-align: center;
  }

  form, .form {

    .htmlForm {
      width: 80vw;
      margin: auto;
      padding: 40px 64px;
      overflow-y: scroll;
      box-shadow: 0 0 24px f.$shadow;

      table {
        width: 100%;
        margin: 0 auto !important;
      }
      
      span {
        background-color: transparent !important;
      }
      
      td p {
        height: auto !important;
        padding: 4px !important;
      }
    }

    input {
      margin: 4px;
      padding: 4px 8px !important;
      background-color: f.$cultured !important;

      &.error {
        border: 1px solid red;
      }

      &.readonly {
        background-color: f.$seaweed !important;
        color: f.$white;

        &::placeholder {
          color: f.$white;
        }
      }
    }
  }

  .buttonWrap {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .downloadButton {
      @include f.buttonSet(f.$gray);
      cursor: not-allowed;
      transition: .5s;

      &.can {
        @include f.buttonSet(f.$orange);
      }
    }
  }

  .downloadModal {
    visibility: hidden;
    opacity: 0;
    //top: 50%;
    //left: 50%;
    //z-index: 10;
    transform: translate(-50%, -50%);
    //padding: 15% 10%;
    border: 5px solid f.$skyblue;
    border-radius: 10px;
    background-color: f.$cultured;
    transition: .2s;

    &.on {
      visibility: visible;
      opacity: 1;
    }

    &.error {
      border: 5px solid f.$red;
    }

    > p {
      color: f.$navy;
      font: {
        weight: bold;
        size: 25px;
      }
      white-space: nowrap;
    }
  }
}