/*************** Import ***************/
@use "foundations/import" as f;
@tailwind base;
@tailwind components;
@tailwind utilities;

/*************** Base ***************/
html {
  font-size: 70.5%;
  font-family: "Helvetica Neue",
  Arial,
  "Hiragino Kaku Gothic ProN",
  "Hiragino Sans",
  Meiryo,
  sans-serif;
  color: f.$navy;
}

// 見出しタグ
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 4.8rem;

  @include f.max-screen(f.$breakpoint-tablet) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 4rem;
  @include f.max-screen(f.$breakpoint-tablet) {
    font-size: 2.5rem;
  }
}

h3 {
  font-size: 3.2rem;
  @include f.max-screen(f.$breakpoint-tablet) {
    font-size: 2.2rem;
  }
}

h4 {
  font-size: 2.4rem;
  @include f.max-screen(f.$breakpoint-tablet) {
    font-size: 1.8rem;
  }
}

h5 {
  font-size: 1.6rem;
}

a {
  color: f.$navy;

  &:visited {
    color: inherit;
  }
}
// pタグ
p {
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
strong,
button,
li,
span {
  color: f.$navy;
}

.bold {
  font-weight: bold;
}
// ulタグ
ul {
  list-style: none;
}

// liタグ
li {
  list-style: none;
}

// aタグ
a {
  display: block;
  text-decoration: none;
}
// iconタグ
i {
  display: block;
  align-items: center;
}
// imgタグ
img {
  width: 100%;
}
// labelタグ
label {
  cursor: pointer;
  line-height: 2.5rem;

  @include f.min-screen(f.$breakpoint-pc) {
    white-space: nowrap;
  }
}
// input, textareaタグ
input,
textarea,
select {
  border: none;
  line-height: 1.5;

  &:focus {
    outline: none;
  }
}
// buttonタグ
button {
  //border: none;

  &:focus {
    outline: none;
  }
}

.tableLayout {
  //border: 1px solid #ddd;

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .td {
    background: f.$white;
  }

  .th,
  .td {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    overflow: hidden;

    :last-child {
      border-right: 0;
    }

    .resizer {
      display: inline-block;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;

      &.isResizing {
        background: red;
      }
    }
  }

  &.stickyLayout {
    overflow: scroll;
    .headerLayout,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .headerLayout {
      top: 0;
      box-shadow: 0 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0 -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0 3px #ccc;
    }
  }
}


/*************** Dynamic Class ***************/
.shadow {
  box-shadow: 0 4px 16px -4px f.$shadow;
  border-radius: 8px;
}

/*************** Button Component ***************/
.updateButton {
  @include f.buttonSet(f.$skyblue);
  transition-duration: 0.3s;

  &.disable {
    cursor: not-allowed;
    border-color: transparent;
    background: f.$gray;
    color: f.$white;
  }

  &.success {
    border-color: transparent;
    background: f.$green;
    color: f.$white;
  }

  &.error {
    cursor: not-allowed;
    border-color: transparent;
    background: f.$red;
    color: f.$white;
  }
}

.cancelButton {
  @include f.buttonSet(f.$vermilion);
}

.careerUpdateButton {
  @include f.buttonSet(f.$skyblue);
}

.switchButton {
  @include f.buttonSet(f.$navy);

  &.disable {
    cursor: not-allowed;
    border-color: transparent;
    background: f.$gray;
    color: f.$white;
  }

  &.success {
    border-color: transparent;
    background: f.$green;
    color: f.$white;
  }

  &.error {
    cursor: not-allowed;
    border-color: transparent;
    background: f.$red;
    color: f.$white;
  }
}

/*************** Input Component ***************/
.interviewInput {
  width: 100%;
  padding: 10px 0;
  border: solid 2px f.$navy;
  border-radius: 7px;
  transition: 0.3s;

  &:focus {
    border-color: solid 2px f.$skyblue;
  }
}

/*************** Overlay Sets ***************/
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  background: transparent;
  content: "";
}

/*************** Extend Class  ***************/
// 継承用class
.popupSets {
  position: absolute;
  z-index: 100;
  border-radius: 8px;
  background: f.$white;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
}

// Overlay
.overlaySet {
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  //background: f.$black;
}

/* *************** Common class *************** */
.loginInfo {
  border-radius: 5px;
  background: f.$navy;

  .loginUser {

    > p {

      > p, span {
        color: f.$white;
      }
    }
  }

  .accountMenu {
    background: f.$white;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 40%);
  }
}

.toggleButton {
  position: relative;
  width: 74px;
  height: 36px;
  border-radius: 2px;
  overflow: hidden;

  .squareWrap:before,
  .squareWrap:after,
  .square {
    position: absolute;
    top: 4px;
    width: 30px;
    height: 80.5%;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 2px;
    transition: 0.3s ease all;
  }

  > input {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked + .square {
      color: lighten(f.$navy, 15%);
    }

    &:checked + .squareWrap:before {
      left: 40px;
      background-color: f.$white;
    }

    &:checked + .squareWrap:after {
      color: f.$skyblue;
    }

    &:checked ~ .layer {
      background-color: f.$skyblue;
    }
  }

  .squareWrap,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .squareWrap {
    z-index: 2;

    &:before {
      left: 4px;
      background-color: f.$white;
      content: '';
    }

    &:after {
      right: 4px;
      color: darken(f.$skyblue, 10%);
      content: 'ON';
    }

    .square {
      display: inline-block;
      left: 4px;
      z-index: 1;
      color: f.$navy;
    }
  }

  .layer {
    z-index: 1;
    width: 100%;
    background-color: f.$navy;
    transition: 0.3s ease all;
  }
}

/* *************** Dynamic class *************** */
@for $size from 0 through 200 {
  @each $unit, $ut in f.$unit {
    @each $direction, $dir in f.$direction {
      // position direction size
      .pos-#{$dir}#{$size}-#{$unit} {
        #{$direction}: #{$size}#{$ut};
      }

      // margin direction
      .m#{$dir}#{$size}-#{$unit} {
        margin-#{$direction}: #{$size}#{$ut};
      }
      .m#{$dir}-auto {
        margin-#{$direction}: auto;
      }

      // padding direction
      .p#{$dir}#{$size}-#{$unit} {
        padding-#{$direction}: #{$size}#{$ut};
      }
    }

    // transform
    @each $transform, $trans in f.$transform {
      .tf#{$trans}-#{$size}#{$unit} {
        transform: #{$transform}(#{$size}#{$ut});
      }
      // minus
      .tf#{$trans}-m#{$size}#{$unit} {
        transform: #{$transform}(-#{$size}#{$ut});
      }
      .tftXY-m#{$size}#{$unit} {
        transform: translate(-#{$size}#{$ut}, -#{$size}#{$ut});
      }
    }

    // min-width
    .minw#{$size}-#{$unit} {
      min-width: #{$size}#{$ut};
    }
    // Width
    .w#{$size}-#{$unit} {
      width: #{$size}#{$ut};
    }

    // min-height
    .minh#{$size}-#{$unit} {
      min-height: #{$size}#{$ut};
    }
    // Height
    .h#{$size}-#{$unit} {
      height: #{$size}#{$ut};
    }

    // margin all
    .m#{$size}-#{$unit} {
      margin: #{$size}#{$ut};
    }
    // margin Top & Bottom
    .mv#{$size}-#{$unit} {
      margin-top: #{$size}#{$ut};
      margin-bottom: #{$size}#{$ut};
    }
    .mv-auto {
      margin-top: auto;
      margin-bottom: auto;
    }
    // margin Right & Left
    .mh#{$size}-#{$unit} {
      margin-right: #{$size}#{$ut};
      margin-left: #{$size}#{$ut};
    }
    .mh-auto {
      margin-right: auto;
      margin-left: auto;
    }

    // padding all
    .p#{$size}-#{$unit} {
      padding: #{$size}#{$ut};
    }
    // padding Top & Bottom
    .pv#{$size}-#{$unit} {
      padding-top: #{$size}#{$ut};
      padding-bottom: #{$size}#{$ut};
    }
    // padding Right & Left
    .ph#{$size}-#{$unit} {
      padding-right: #{$size}#{$ut};
      padding-left: #{$size}#{$ut};
    }

    // border-radius
    .br#{$size}-#{$unit} {
      border-radius: #{$size}#{$ut};
    }

    // font-size
    .fs#{$size}-#{$unit} {
      font-size: #{$size}#{$ut};
    }

    // line-height
    .lh#{$size}-#{$unit} {
      line-height: #{$size}#{$ut};
    }
  }

  // flex
  .f#{$size} {
    flex: #{$size};
  }

  // z-index
  .z#{$size} {
    z-index: #{$size};
  }

  // line-height
  .lh#{$size} {
    line-height: #{$size};
  }
}

@each $display, $dis in f.$display {
  // display
  .dis-#{$dis} {
    display: #{$display};
  }
}

@each $position, $pos in f.$position {
  // position
  .pos-#{$pos} {
    position: #{$position};
  }
}

@each $objectType, $ob in f.$objectType {
  // object-type
  .of-#{$ob} {
    object-fit: #{$objectType};
  }
}

// Flex
@each $flexType, $fType in f.$flexType {
  @each $flexDirection, $fDir in f.$flexDirection {
    .f#{$fType}-#{$fDir} {
      #{$flexType}: $flexDirection;
    }
  }
}

// overflow
@each $overflow, $over in f.$overflow {
  @each $axis, $ax in f.$axis {
    .over#{$ax}-#{$over} {
      overflow-#{$axis}: $overflow;
    }
  }
  .over-#{$over} {
    overflow: $overflow;
  }
}

// text-align
@each $direction, $dir in f.$direction {
  .ta-#{$dir} {
    text-align: #{$direction};
  }
}

// white-space
@each $whiteSpace, $whspace in f.$whiteSpace {
  .ws-#{$whspace} {
    white-space: #{$whiteSpace};
  }
}

// word-break
@each $wordBreak, $word in f.$wordBreak {
  .wb-#{$word} {
    word-break: #{$wordBreak};
  }
}

// cursor
@each $cursor, $cur in f.$cursor {
  .cur-#{$cur} {
    cursor: #{$cursor}
  }
}

/*************** Color Class ***************/
// background
.navy {
  background-color: f.$navy;
}
.sapphire {
  background-color: f.$sapphire;
}
.seaweed {
  background-color: f.$seaweed;
}
.skyblue {
  background-color: f.$skyblue;
}
.white {
  background-color: f.$white;
}
.black {
  background-color: f.$black;
}
.jet {
  background-color: f.$jet;
}
.gray {
  background-color: f.$gray;
}
.silver {
  background-color: f.$silver;
}
.cultured {
  background-color: f.$cultured;
}
.pratinum {
  background-color: f.$platinum;
}
.red {
  background-color: f.$red;
}
.vermilion {
  background-color: f.$vermilion;
}
.orange {
  background-color: f.$orange;
}
.green {
  background-color: f.$green;
}
.purple {
  background-color: f.$purple;
}
.yellow {
  background-color: f.$yellow;
}

// Color Class