/*************** Import ***************/
@use "../foundations/import" as f;

.menuComponent {
  display: flex;
  position: relative;
  min-height: 100%;
  z-index: 40;

  .alwaysMenu {
    width: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    padding: 8px 8px 16px 8px;
    background: f.$navy;
    text-align: center;

    .topIcons {

      .logoIcon {
        display: flex;
        height: 48px;
        margin-bottom: 16px;
      }
    }

    .menuIcon {
      padding-bottom: 8px;
      cursor: pointer;

      .menuIcon-link {
        width: 48px;
        height: 48px;
        display: flex;
        border-radius: 100%;
        justify-content: center;
        transition: all ease-in 260ms;
        border: 1px solid f.$navy;

        &:hover {
          border: 1px solid f.$gray;
          transition: all linear 260ms;
        }
      }

      svg {
        color: f.$white;
        width: 24px;
        height: 24px;
        margin: auto;
      }
    }

    .helpIcons {
      .menuIcon {
        position: relative;
        border-radius: 2px;

        .menuIcon-link {
          .far,
          .fas,
          .fab {
            padding: 6px 3px;
            color: f.$white;
            margin: auto;
          }
        }

        &:hover {
          .hoverPopup {
            opacity: 1;
          }
        }

        .hoverPopup {
          opacity: 0;
          pointer-events: none;
          cursor: default;
          position: absolute;
          top: 24px;
          left: 64px;
          padding: 8px 16px;
          border-radius: 3px;
          background: f.$navy;
          white-space: nowrap;
          transform: translateY(-50%);
          box-shadow: 0 4px 16px -4px #0d2f473b;
          color: white;

          &:before {
            position: absolute;
            top: 50%;
            left: -4px;
            border-style: solid;
            border-width: 5px 5px 5px 0;
            border-color: transparent f.$navy transparent transparent;
            transform: translateY(-50%);
            content: "";
          }
        }

        &:last-child {
          padding: 0;
        }
      }
    }
  }

  .popupHelp {
    @extend .popupSets;
    bottom: 18px;
    left: 68px;
    padding: 8px;
    background: f.$navy;
    color: white;
    transition: all ease 300ms;
    pointer-events: none;
    opacity: 0;
    transform: translateY(24px);

    &.visible {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    }

    li {
      > .popupHelp-link {
        padding: 8px;
        white-space: nowrap;
        transition: 0.3s;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          background: lighten(f.$navy, 15%);
          transition: all ease-in 260ms;
        }

        > svg {
          margin-right: 8px;
        }
      }
    }
  }

  .openMenu {
    width: 250px;
    height: 100%;
    padding: 8px;
    border-left: 1px solid f.$sapphire;
    background: f.$navy;
    transition: all linear 260ms;
    transform: translateX(-100%);
    position: absolute;
    left: 100%;
    top: 0;

    &.opened {
      transform: translateX(0);
    }

    .menu_title {
      height: 56px;
      opacity: 0.5;
      padding: 16px 8px;
      color: f.$white;
    }

    > ul {
      .menuList {
        width: 100%;
        border-radius: 8px;
        cursor: pointer;
        background: f.$navy;
        //transition: all ease-in 0;

        &:hover {
          background: lighten(f.$navy, 15%);
          transition: all ease-in 260ms;

          .subMenu {
            opacity: 1;
            transform: translateX(0);
          }
        }

        .menuList_link {
          display: block;
          position: relative;
          margin: 0 auto;
          padding: 16px 8px;
          color: f.$white;
          text-decoration: none;

          &.now {
            border-radius: 8px;
            //background: $skyblue;
          }
        }

        .active {
          border-radius: 2px;
          background: f.$skyblue;
          font-weight: bold;

          &:hover {
            color: f.$gray;
          }
        }

        .subMenu {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 100%;
          z-index: 10;
          width: 100%;
          border-radius: 5px;
          transform: translateX(-30%);
          transition: 0.8s;

          li {
            .menuIcon-link {
              display: block;
              padding: 8px;
              border-radius: 2px;
              background: lighten(f.$navy, 15%);
              color: f.$white;
              font-weight: normal;

              &:hover:not(.active) {
                background: f.$skyblue;
                color: f.$white;
                font-weight: bold;
              }
            }

            .active {
              background: f.$skyblue;
              color: f.$white;
              font-weight: bold;
            }
          }
        }
      }
    }

    &.close {
      display: none;
    }
  }

  // tablet以下なら
  @include f.max-screen(f.$breakpoint-tablet) {
    display: none;
  }
}

/*************** Trash Button ***************/
.trashButton {
  border: solid 1px transparent;
  background: transparent;
  color: f.$white;
  transition: .3s;

  &:hover {
    border: solid 1px f.$white;
    color: f.$white;
  }
}


/*************** Extend Class  ***************/
// 継承用class
.popupSets {
  position: absolute;
  z-index: 100;
  border-radius: 8px;
  background: f.$white;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
}