/*************** Import ***************/
@use "../../foundations/import" as f;

/*************** Styles ***************/

main {

  &.detailsStatus {

    > h2 {
      text-align: center;
    }

    .ownStatuses {

      > h2 {
        color: f.$white;
      }

      > ul {

        @include f.min-screen(f.$breakpoint-pc) {
          width: 50%;
          margin: {
            right: auto;
            left: auto;
          }
        }

        > li {

          &:not(:first-child) {
            margin-top: 5%;

            &:nth-child(2n) {
              .statusContents {
                background: f.$skyblue;
              }
            }

            .statusContents {
              margin-top: 5%;
            }
          }

          > p {
            position: relative;
            font-weight: bold;
            margin-left: 45%;

            &:after {
              position: absolute;
              left: 20%;
              width: 3px;
              height: 100%;
              background: f.$skyblue;
              content: '';
              animation: extendLine 2s infinite;
            }
          }

          .statusContents {
            display: flex;
            justify-content: space-between;
            padding: 5%;
            border-radius: 5px;
            background: f.$navy;
            color: f.$white;

            p {
              color: f.$white;
              font-weight: bold;

              &.done {
                color: f.$green;
              }

              &.yet {
                color: f.$pastelRed;
              }
            }

            .statusValue {
              display: flex;

              > p {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}